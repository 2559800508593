<template>
  <div class="field-wysiwyg">
    <ckeditor v-model="data" :config="options" :readOnly="disabled"></ckeditor>
  </div>
</template>

<script>



export default {

  data() {
    return {
      data: this.value,
    }
  },

  model: {
    prop: 'value',
  },

  props: {
    value: String,
    placeholder: String,
    disabled: Boolean,
  },

  watch: {
    data(val) {
      this.$emit('input', val)
    },

    value(val) {
      this.data = val
    }
  },

  computed: {
    options() {
      return {
        extraPlugins: [
            'justify',
            'colorbutton',
            'removeformat'
        ],
        removeButtons: [],
        toolbar: [
            [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat' ],
            [ 'Styles', 'Format', 'Font', 'FontSize' ],
            [ 'NumberedList', 'BulletedList', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
            [ 'BGColor', 'TextColor'],
            [ 'SpecialChar' ],
            [ 'Maximize','Subscript', 'Superscript' ],
        ],
        // toolbar: [
        //
        //   { name: 'document', groups: [ 'mode', 'document', 'doctools' ], items: [ 'Source', '-', '-' ] },
        //   { name: 'clipboard', groups: [ 'clipboard', 'undo' ], items: [ 'Cut', 'Copy', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
        //   { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ], items: [ '-', '-', 'Scayt' ] },
        //   { name: 'forms', groups: [ 'forms' ], items: [ '' ] },
        //   { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ], items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'Language' ] },
        //   { name: 'links', groups: [ 'links' ], items: [ 'Link', 'Unlink', 'Anchor' ] },
        //   { name: 'insert', groups: [ 'insert' ], items: [ 'Image', 'Table', 'SpecialChar' ] },
        //   { name: 'styles', groups: [ 'styles' ], items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
        //   { name: 'colors', groups: [ 'colors' ], items: [ 'TextColor', 'BGColor' ] },
        //   { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-' ] },
        //   { name: 'tools', groups: [ 'tools' ], items: [ 'Maximize' ] },
        //   { name: 'others', groups: [ 'others' ], items: [ '-' ] },
        //   { name: 'about', groups: [ 'about' ], items: [ 'About' ] }
        // ],
        //
        // // Toolbar groups configuration.
        // toolbarGroups: [
        //   { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
        //   { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
        //   { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
        //   { name: 'forms', groups: [ 'forms' ] },
        //   { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
        //   { name: 'links', groups: [ 'links' ] },
        //   { name: 'insert', groups: [ 'insert' ] },
        //   { name: 'styles', groups: [ 'styles' ] },
        //   { name: 'colors', groups: [ 'colors' ] },
        //   { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
        //   { name: 'tools', groups: [ 'tools' ] },
        //   { name: 'others', groups: [ 'others' ] },
        //   { name: 'about', groups: [ 'about' ] }
        // ]
      }
    },
  }
}
</script>

<style lang="scss">
  .field-wysiwyg {

  }
</style>