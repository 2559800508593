import Vue from 'vue'
import App from './App.vue'
import vuetify from '@plugins/vuetify'
import '@plugins/common-filters'
import '@plugins/common-directives'
import '@plugins/vuetify-dialog'

import CKEditor from 'ckeditor4-vue';
Vue.use( CKEditor );


import VueJWT from '@plugins/vue-jwt'
import router from './router'
import store from './stores'
import i18n from '@plugins/i18n'
import dayjs from 'dayjs'

import HighchartsVue from 'highcharts-vue'
Vue.use(HighchartsVue)

import VueNumber from "vue-number-animation";
Vue.use(VueNumber);

require('dayjs/locale/fr')
require('dayjs/locale/nl')

dayjs.extend(require('dayjs/plugin/duration'))
dayjs.extend(require('dayjs/plugin/isSameOrBefore'))

require('@includes/webfonts/default.font')
require('@styles/app.scss')

import Textfield from "@blocks/Textfield";
import TitlePage from "@blocks/TitlePage";
import SearchField from "@blocks/SearchField";
import NavButton from "@blocks/NavButton";
import BackButton from "@blocks/BackButton";
import ChipStatus from "@blocks/ChipStatus";
import CommonMixin from "@/components/mixins/CommonMixin";
import Headline from "@blocks/Headline";
import FormField from "@blocks/FormField";
import {VStepperIcon, VStepperStepIcon} from "@plugins/vuetifiy-stepper-icon";


Vue.config.productionTip = false


Vue.component('Textfield', Textfield)
Vue.component('TimePicker', TimePicker)
Vue.component('DatePicker', DatePicker)
Vue.component('TitlePage', TitlePage)
Vue.component('SearchField', SearchField)
Vue.component('NavButton', NavButton)
Vue.component('BackButton', BackButton)
Vue.component('ChipStatus', ChipStatus)
Vue.component('Headline', Headline)
Vue.component('FormField', FormField)
Vue.component('VStepperIcon', VStepperIcon)
Vue.component('VStepperStepIcon', VStepperStepIcon)

Vue.mixin(CommonMixin)


Vue.use(VueJWT, {
  authRedirect: {path: '/login'},
  loginData: { url: '/api/login_check', fetchUser: false, withUser: true, withRefreshToken: true }, //
  logoutData: {url: '/api/logout', method: 'POST', redirect: '/login', makeRequest: false},
  registerData: {url: '/api/register', method: 'POST', redirect: '/'},
  fetchData: { url: '/api/info', method: 'GET', enabled: true },
  refreshData: { enabled: false }
})


import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import DatePicker from "@blocks/DatePicker";
import TimePicker from "@blocks/TimePicker";
Vue.use(VueTelInputVuetify, {
  vuetify,
});


router.beforeEach((to, from , next) => {
  if(global.formDataNotSaved) {
    const answer = router.app.$dialog.confirm({
      icon: 'mdi-alert',
      type: 'warning',
      persistent: true,
      title: router.app.$t('confirm-unsaved-changes-title'),
      text: router.app.$t('confirm-unsaved-changes-text')
    }).then(result => {
      if (result) {
        global.formDataNotSaved = false
        next()
      } else {
        next(false)
      }
    })
  }
  else {
    next()
  }
})

new Vue({
  vuetify,
  store,
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
